import React from 'react'
import Layout from '../components/layout'

function page404() {
    return (
        <Layout>
            <h1>Something went wrong</h1>
        </Layout>
    )
}

export default page404
